<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Skema Rujukan</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body">
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Skema Rujukan:</label>
                    <div class="col-lg-6">
                        <select name="skemaRujukan" class="form-control" v-model="skemaRujukanId" @change="changeSkema()">
                            <option :value="null" disabled selected >Pilih Skema Rujukan</option>
                            <option :value="data.id" v-for="data in modalData" :key="data.id">{{data.nama}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-3" v-if="isLoaded">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Tindakan</th>
                                <th width="15%" v-for="item in kelas" :key="item.id">{{ item.nama }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(parent) in skemaRujukan.parentTindakans" >
                                <tr :key="parent.id">
                                    <th> {{ parent.nama }}</th>
                                    <th v-for="item in kelas" :key="item.id"></th>
                                </tr>
                                <tr v-for="(sub) in parent.subTindakans" :key="sub.id">
                                    <td>{{sub.nama}}</td>
                                    <td v-for="fee in sub.fee" :key="fee.id" class="text-right">
                                        <template v-if="!fee.usePersentase">
                                            <div class="kt-radio-list float-right">
                                                <label class="kt-radio">
                                                    <input type="radio" :value="fee.id" name="feeId" v-model="feeId"> {{fee.biaya | seperator }}
                                                    <span></span>
                                                </label>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col float-right pr-0"> {{ fee.persentase }} % </div>
                                            <div class="col pr-0" v-if="fee.biayaMin">
                                                <div class="kt-radio-list float-right ">
                                                    <label class="kt-radio">
                                                        <input type="radio" :value="fee.id" name="feeId" v-model="feeId"> <strong> Min : </strong> &nbsp; {{ fee.biayaMin | seperator }}
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col float-right pr-0" v-if="fee.biayaMax"> <strong> Max : </strong> &nbsp; {{ fee.biayaMax | seperator }}</div>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">Save</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
  components: {},
  data() {
    return {
      skemaRujukan: null,
      isLoaded: false,
      kelas:[],
      skemaRujukanId: null,
      feeId: null
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Array },
    selected: {type: Object}
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  filters: {
    seperator: function(value) {
      if (value !== null) {
        const parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
      }
      return value;
    }
  },
  methods: {
    changeSkema: function() {
        this.skemaRujukan = this.modalData.find(x => x.id === this.skemaRujukanId);
        this.getKelas();
    },
    getKelas: function() {
      this.isLoaded = false;
      let totalKelas = [];
      this.skemaRujukan.parentTindakans.forEach(parent => {
        parent.subTindakans.forEach(sub => {
          const kelas = sub.fee.map(x => x.kelasCustom);
          totalKelas = totalKelas.concat(kelas);
        });
      });
      const result = [];
      const map = new Map();
      totalKelas.forEach(item => {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          result.push(item);
        }
      });
      result.sort((a, b) => a.kelasOrder - b.kelasOrder);
      this.kelas = result;
      this.constructData();
    },
    constructData: function() {
      this.skemaRujukan.parentTindakans.forEach(parent => {
        parent.subTindakans.forEach(sub => {
          if (sub.fee.length > 0) {
            sub.usePersen = false;
            sub.usePersen = sub.fee[0].usePersentase;
            // check apakah sama semua fee nya
            if (sub.usePersen) {
              sub.isAll =
                sub.fee
                  .map(x => x.persentase)
                  .every((val, i, arr) => val === arr[0]) &&
                sub.fee
                  .map(x => x.biayaMin)
                  .every((val, i, arr) => val === arr[0]) &&
                sub.fee
                  .map(x => x.biayaMax)
                  .every((val, i, arr) => val === arr[0])
                  ? true
                  : false;
            } else {
              sub.isAll = sub.fee
                .map(x => x.biaya)
                .every((val, i, arr) => val === arr[0]);
            }
            // chck apakah fee nya ada yg kosong
            if (sub.fee.length !== this.kelas.length) {
              sub.isAll = false;
              this.kelas.forEach(kls => {
                if (!sub.fee.find(x => x.kelasCustom.id === kls.id)) {
                  const fee = new Fee();
                  fee.kelasCustom = kls;
                  fee.usePersentase = sub.usePersen;
                  sub.fee.push(fee);
                }
              });
            }
            sub.fee.sort(
              (a, b) => a.kelasCustom.kelasOrder - b.kelasCustom.kelasOrder
            );
            sub.fee.forEach(fee => {
              fee.msgError = null;
              fee.isReadonly = sub.isAll;
            });
          } else {
            sub.isAll = false;
            sub.usePersen = false;
            this.kelas.forEach(kls => {
              const fee = new Fee();
              fee.kelasCustom = kls;
              sub.fee.push(fee);
            });
          }
        });
      });
      this.isLoaded = true;
    },
    clearData: function() {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    updateData: function(data) {
        this.skemaRujukanId = data.skemaRujukan.id;
        this.feeId = data.id;
        this.changeSkema();
    },
    submitData: function(){
        if (this.skemaRujukanId && this.feeId) {
            let selectedSkemaFee = null;
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    const nfee = sub.fee.find(m => m.id === this.feeId);
                    if (nfee) {
                        let nsub =  Object.assign({},sub);
                        let fee =  Object.assign({},nfee);
                        delete fee.kelasCustom.kelasOrder;
                        delete fee.kelasCustom.uuid;
                        delete nsub.fee;
                        delete nsub.usePersen;
                        delete nsub.isAll;
                        delete nsub.parent.fee;
                        selectedSkemaFee = {
                            id: fee.id,
                            biaya: fee.biaya,
                            biayaMax: fee.biayaMax,
                            biayaMin: fee.biayaMin,
                            usePersentase: fee.usePersentase,
                            persentase: fee.persentase,
                            kelas: fee.kelasCustom,
                            tindakan: nsub,
                            skemaRujukan: this.skemaRujukan
                        }
                    }
                });
            });
            this.$emit('update', selectedSkemaFee)
        }
        this.clearData();
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.selected) {
      this.updateData(this.selected);
    }
  }
};
</script>

<style>
</style>
